.toolbar {
  height: 100%;
  background-color: white;
  border-radius: 5px 5px 0 0;
  padding: 8px;
}
.input-field {
  height: 600px;
  width: 400px;
  display: flex;
  word-wrap: break-word;
  flex-wrap: wrap;
}
.input-field-wrapper {
  width: 200px; /* Set your desired width for the wrapper */
  height: 100px; /* Set a fixed height for the wrapper */
  overflow: auto; /* Enable scrolling if content exceeds the height */
}
.fillInBlankstextarea {
  display: flex;
  resize: none;
  outline: none;
  user-select: none;
  font-family: Arial, sans-serif;
  font-size: 16px;
}
.essaytextarea {
  height: 500px;
  width: 100%;
  display: flex;
  resize: none;
  outline: none;
  user-select: none;
  font-family: Arial, sans-serif;
  font-size: 16px;
}

.textfield {
  width: 100%; /* Ensure the input takes up the full width of the wrapper */
  height: 100%; /* Take up the full height of the wrapper */
}

.textfield input {
  width: 100%; /* Ensure the input takes up the full width of the wrapper */
  height: 100%; /* Take up the full height of the wrapper */
  word-wrap: break-word; /* Enable word wrapping for long words */
  overflow-wrap: break-word; /* Another option for older browsers */
}

.ql-editor {
  font-size: 20px;
}
.textfield {
  width: 800px;

  word-wrap: break-word;
}
.editor-section {
  border-right: 1px solid #d5d6dc;
  padding-right: 15px;
}
.editor-item {
  margin-right: 1px;
}

.react-quill-div {
  height: 25rem;
  background-color: white;
  word-break: break-word;
}

.ql-container {
  border-radius: 0 0 5px 5px;
}

.ql-snow .ql-stroke {
  stroke: #262b4b !important;
  stroke-width: 2 !important;
}

.ql-snow.ql-toolbar button svg,
.ql-snow .ql-toolbar button svg {
  height: 14px !important;
}
.ql-snow .ql-picker-label::before {
  font-size: 14px;
}

.ql-toolbar.ql-snow {
  padding: unset;
  padding: 0px !important;
}
.ql-container.ql-snow {
  border: unset;
  border-bottom: none !important;
  border-right: none !important;
  border-left: none !important;
  /* border-top: 1px solid #ccc !important; */
}

.quill > .ql-container > .ql-editor.ql-blank::before {
  font-size: 20px;
  font-style: normal;
}

@media only screen and (max-width: 599px) {
  .ql-editor {
    font-size: 16px;
  }
  .quill > .ql-container > .ql-editor.ql-blank::before {
    font-size: 16px;
    font-style: normal;
  }
}

.ql-snow.ql-toolbar button:hover:not(.ql-active),
.ql-snow .ql-toolbar button:hover:not(.ql-active) {
  color: white !important;
}

.ql-snow.ql-toolbar button:hover,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar button:focus,
.ql-snow .ql-toolbar button:focus,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: white !important;
  stroke: white !important;
  background-color: #00b7a4 !important;
  border-radius: 5px;
}

.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow .ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar button:focus .ql-stroke,
.ql-snow .ql-toolbar button:focus .ql-stroke,
.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-snow .ql-toolbar button.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: white !important;
}
.MuiBox-root-169 {
  width: 100%;
}
